import { Box, IconButton } from '@mui/material';

import UserAvatar from 'components/UserAvatar';
import NavigationContext from 'core/contexts/navigationProvider/NavigationContext';
import { theme } from 'core/theme';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { selectAccount } from 'state/accountSlice';
import DjobLogo from 'assets/images/DjobLogo.png';

import packageJson from '../../../package.json';
import { NavBarWrapper } from './style';
import { t } from 'i18next';
import { Screens } from 'core/routes/Screens';

console.info(`djob v${packageJson.version}`);

const buttonIsActive = (arr: string[], pathname: string) =>
  arr.map((e) => pathname.startsWith(e)).some((e) => e);

const Navbar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { menuIsShown } = useContext(NavigationContext);
  const { account } = useSelector(selectAccount);
  if (!menuIsShown(pathname)) return null;

  const myRewardsButtonIsActive = buttonIsActive(
    [
      `${Screens.SpendingTokens.path}/${Screens.Happening.path}`,
      `${Screens.SpendingTokens.path}/${Screens.MyCollection.path}`,
    ],
    pathname
  );
  /*
  const myCompanyButtonIsActive = buttonIsActive(
    [
      `${Screens.MyCompany.path}/${Screens.MyCompanyHappening.path}`,
      `${Screens.MyCompany.path}/${Screens.MyCompanyScoreBoard.path}`,
    ],
    pathname
  );
*/
  return (
    <NavBarWrapper id="main-header">
      <Box className="NavBarWrapper__wrapper">
        <Box className="NavBarWrapper__content">
          <NavLink
            to="/"
            onClick={() => window.scrollTo(0, 0)}
            className={'NavBarLogo'}
            style={{ paddingInlineEnd: theme.spacing(3), fontSize: 0, borderBottom: 0 }}
          >
            <img
              src={DjobLogo}
              alt={'djob'}
              style={{
                height: '5.1rem',
                cursor: 'pointer',
              }}
            />
          </NavLink>
          <Box className="NavBarWrapper__content_links">
            <NavLink to="/">
              <>{t('V3_ASIDE_MENU.LABEL_HOME')} 🔥</>
            </NavLink>

            <NavLink
              to={`${Screens.SpendingTokens.path}/${Screens.Marketplace.path}`}
              className={myRewardsButtonIsActive ? 'active' : ''}
            >
              <>{t('V3_ASIDE_MENU.LABEL_SPENDING_TOKENS')} 🎁</>
            </NavLink>
          </Box>
        </Box>
        <Box>
          <IconButton
            name="profile-redirection-button"
            className="profileIconButton"
            onClick={() => {
              navigate('/profile');
              window.scrollTo(0, 0);
            }}
          >
            <UserAvatar
              size={32}
              firstName={account?.firstName as string}
              lastName={account?.lastName as string}
              pictureUrl={account?.picture}
              fontSize={1.5}
            />
          </IconButton>
        </Box>
      </Box>
    </NavBarWrapper>
  );
};

export default Navbar;
