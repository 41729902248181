import { Box, styled, useMediaQuery } from '@mui/material';

export const DefaultLayoutWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  minHeight: '100svh',
  '&>.DefaultLayout__inner': {
    maxWidth: theme.breakpoints.values.xl,
    width: '100%',
    minHeight: '100%',
    margin: '0 auto',
    '&>.DefaultLayout__main-container': {
      height: '100%',
      padding: useMediaQuery(theme.breakpoints.down('md')) ? 0 : theme.spacing(3),
      display: 'flex',
      gap: theme.spacing(4),
    },
  },
  '& .DefaultLayout__leftSide, .DefaultLayout__rightSide': {
    display: useMediaQuery(theme.breakpoints.down('md')) ? 'none' : 'inherit',
    zIndex: '1',
    '&.has__blurry_bg': {
      '&:before': {
        content: '""',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100dvh',
        display: 'block',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
      },
    },
  },
  '& .DefaultLayout__footer': {
    backgroundColor: 'var(--semantics-surface-secondary-background)',
    borderRadius: `${theme.spacing(2)} ${theme.spacing(2)} 0px 0px`,
    position: 'sticky',
    padding: '1rem',
    bottom: 0,
    zIndex: 1,
    display: useMediaQuery(theme.breakpoints.up('md')) ? 'none' : 'inherit',
  },
}));
