import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { FooterContent } from 'features/navigation/FooterContent';
import Navbar from 'features/navigation/Navbar';
import { Outlet } from 'react-router-dom';
import Store from './DefaultLayout.store';
import { DefaultLayoutWrapper } from './DefaultLayout.style';
import { useSelector } from 'react-redux';
import { selectAccount } from 'state/accountSlice';
import { Suspense } from 'react';
import { theme } from 'core/theme';
import { SplashScreen } from 'components/SplashScreen';

export const DefaultLayout = () => {
  const { settings } = Store.useStore((state) => state);
  const { account } = useSelector(selectAccount);
  const { RightSideBarContent, hasRightSideBarContent } = settings;
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <DefaultLayoutWrapper className="DefaultLayout">
      {!account.isFromTeams ? <Navbar /> : null}
      <Box className="DefaultLayout__inner">
        <Box className="DefaultLayout__main-container">
          <Grid container spacing={4} wrap="nowrap" className="DefaultLayout__main-grid">
            <Grid
              item
              xs
              id="mainContent"
              className="DefaultLayout__main-content"
              sx={{
                padding: 0,
              }}
            >
              <Box className="DefaultLayout__mainContent-inner" sx={{ height: '100%' }}>
                <Box
                  className="mainContent-inner"
                  sx={{
                    padding: isMobile ? 2 : 'inherit',
                    height: '100%',
                  }}
                >
                  <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                  </Suspense>
                </Box>
              </Box>
            </Grid>
            {hasRightSideBarContent && (
              <Grid
                item
                xs={4}
                className={`DefaultLayout__rightSide ${
                  settings.hasSplitedRightSideBarContent ? 'has__blurry_bg' : ''
                }`}
              >
                <RightSideBarContent />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      {!account.isFromTeams && (
        <footer className="DefaultLayout__footer">
          <Container>
            <FooterContent />
          </Container>
        </footer>
      )}
    </DefaultLayoutWrapper>
  );
};
